import React from "react";
import { css } from "@emotion/react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const shortcodes = { Link };

export default function Post({ data: { mdx: post } }) {
  return (
    <Layout pageTitle={post.frontmatter.title}>
      <SEO title={post.frontmatter.title} description={post.frontmatter.description} />
      <article>
        <h1
          css={css`
            text-align: center;
            margin-bottom: 2.25rem;
          `}
        >
          {post.frontmatter.title}
        </h1>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
      </article>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
      }
    }
  }
`;
